<template>
  <v-layout max-width class="flex-column ">
    <div class="flex-y-center flex-justify-between white px-4 py-2 mb-3" style="">
      <div />
      <div class=" flex-y-center">
        <div class="flex-y-center mr-2" style="width:120px;">
          <input
            v-model="search.data.project_name"
            placeholder="日志内容"
            class="default-input max-width"
            type="text"
          >
        </div>

        <div class="flex-y-center mr-2" style="width:120px;">
          <v-menu
            :close-on-content-click="false"
            bottom
            max-height="300"
            offset-overflow
            transition="slide-y-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="default-input" color="primary" v-on="on">
                <input v-model="search.data.project_name" placeholder="日志类型" class=" max-width" type="text">
              </div>
            </template>
            <v-list class="pa-0 radius-0" subheader>
              <v-list-item v-for="(item,index) in shiyan" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="flex-y-center mr-2" style="width:120px;">
          <v-menu
            :close-on-content-click="false"
            bottom
            max-height="300"
            offset-overflow
            transition="slide-y-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="default-input" color="primary" v-on="on">
                <input v-model="search.data.project_name" placeholder="操作类型" class=" max-width" type="text">
              </div>
            </template>
            <v-list class="pa-0 radius-0" subheader>
              <v-list-item v-for="(item,index) in shiyan" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="flex-y-center mr-2" style="width:120px;">
          <input
            v-model="search.data.project_name"
            placeholder="IP"
            class="default-input max-width"
            type="text"
          >
        </div>
        <div class="flex-y-center mr-2" style="width:120px;">
          <input
            v-model="search.data.project_name"
            placeholder="操作时间"
            class="default-input max-width"
            type="text"
          >
        </div>
        <div class="flex-y-center mr-2" style="width:120px;">
          <input
            v-model="search.data.project_name"
            placeholder="操作人"
            class="default-input max-width"
            type="text"
          >
        </div>
        <v-btn dark small color="primary" depressed min-width="30" style="height:30px; padding:0;">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn>
        <!-- <v-btn depressed color="primary">搜索</v-btn> -->
      </div>

    </div>
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
      >
      <!-- <template v-slot:item="{expand,item}"> -->
      </v-data-table>
    </div>

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      headers: [
        { text: '日志内容', value: 'username' },
        { text: '日志类型', value: 'realname' },
        { text: '操作类型', value: 'realname' },
        { text: '操作系统', value: 'sex_dictText' },
        { text: '浏览器版本', value: 'birthday' },
        { text: 'IP', value: 'ip' },
        { text: '耗时（毫秒）', value: 'orgCodeTxt' },
        { text: '操作时间', value: 'departIds_dictText' },
        { text: '操作人', value: 'active' }
      ],
      list: [
        { username: '1sdfsdf' }
      ],

      shiyan: [
        { name: '试验类型1', id: '1' },
        { name: '试验类型2', id: '2' },
        { name: '试验类型3', id: '3' }
      ]
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      this.$http.get(this.$api.userList, { ...this.search }).then(res => {
        if (res.code === 0) {
          this.list = res.result.records || []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
